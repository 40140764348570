export const FirebaseConfig = {
	"projectId": "transporteur-8fe13",
	"appId": "1:814335805675:web:317dc6e9a962962ef656ae",
	"databaseURL": "https://transporteur-8fe13-default-rtdb.firebaseio.com",
	"storageBucket": "transporteur-8fe13.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAspowxhuMpHCJwtZMwoh9vc9njfkkeltw",
	"authDomain": "transporteur-8fe13.firebaseapp.com",
	"messagingSenderId": "814335805675",
	"measurementId": "G-R51YTJ9M75"
};